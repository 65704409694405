<template>
  <div id="check_pieces">
    <div class="admin_main_block">
      <div class="user_temp_title">售后查件</div>
      <div class="admin_form_main">
        <div class="daifatu_home">
          <el-form
            :model="ruleForm"
            label-width="100px"
            :rules="rules"
            ref="ruleForm"
          >
            <el-form-item label="快递单号" prop="order_nos" size="small">
              <el-input
                v-model="ruleForm.order_nos"
                size="small"
                :autosize="{ minRows: 14, maxRows: 14 }"
                type="textarea"
                placeholder="快递单号，多个换行隔开"
              ></el-input>
            </el-form-item>
            <el-form-item label="问题描述" prop="remark" size="small">
              <el-input
                v-model="ruleForm.remark"
                size="small"
                :autosize="{ minRows: 6, maxRows: 6 }"
                type="textarea"
                placeholder="请输入问题描述"
              ></el-input>
            </el-form-item>
            <el-form-item label="添加照片">
              <div class="img_wrap" v-if="imgUrl" @click="toFile">
                <img :src="imgUrl" alt="" />
              </div>
              <div class="img_upload" @click="toFile" v-else>
                <i class="el-icon-plus avatar-uploader-icon"></i>
              </div>
              <input
                ref="imgHook"
                style="display: none"
                type="file"
                accept="image/*"
                @change="fileChnage"
              />
            </el-form-item>
            <el-form-item>
              <el-button size="small" type="primary" @click="add"
                >提交</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="admin_table_main">
        <el-tabs
          v-model="searchData.status"
          type="card"
          @tab-click="handleClick"
        >
          <el-tab-pane label="未处理" name="0"></el-tab-pane>
          <el-tab-pane label="已处理" name="1"></el-tab-pane>
          <el-tab-pane label="全部" name="10"></el-tab-pane>
        </el-tabs>
        <el-table :data="list" v-loading="loading">
          <el-table-column label="编号" width="100" prop="id"></el-table-column>
          <el-table-column
            label="快递单号"
            prop="expressNos"
            width="250"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="问题描述"
            prop="issue"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column label="相关照片" prop="img" align="center">
            <template slot-scope="scope">
              <el-image
                :src="$common.getImgUrl(scope.row.img)"
                :preview-src-list="[$common.getImgUrl(scope.row.img)]"
                style="width: 80px; height: 80px"
                v-if="scope.row.img != ''"
              >
              </el-image>
            </template>
          </el-table-column>
          <el-table-column label="处理状态">
            <template slot-scope="scope">
              <span v-if="scope.row.status == 0">处理中</span>
              <span v-if="scope.row.status == 1">已处理</span>
            </template>
          </el-table-column>
          <el-table-column label="平台回复" show-overflow-tooltip>
            <template slot-scope="scope">
              <span v-if="scope.row.remark == null">平台还未处理</span>
              <span v-if="scope.row.remark != 1">{{ scope.row.remark }}</span>
            </template>
          </el-table-column>
          <el-table-column label="提交时间" prop="createTime">
            <template slot-scope="scope">
              {{ (scope.row.createTime / 1000) | date("Y-m-d H:i:s") }}
            </template>
          </el-table-column>
        </el-table>
        <div class="home_fy_block">
          <el-pagination
            v-if="total > 0"
            background
            @current-change="handleCurrentChange"
            :current-page="searchData.pageNo"
            :page-size="searchData.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      imgUrl: "",
      ruleForm: {
        order_nos: "",
        remark: "",
      },
      rules: {
        order_nos: [
          { required: true, message: "快递单号不能为空", trigger: "blur" },
        ],
        remark: [
          { required: true, message: "问题描述不能为空", trigger: "blur" },
        ],
      },
      loading: false, //防重复点击
      searchData: {
        pageNo: 1,
        pageSize: 5,
        status: 0,
        id: localStorage.getItem("ids"),
      },
      list: [],
      total: 0,
    };
  },
  created() {
    this.getList();
  },
  mounted() {},
  watch: {},
  methods: {
    handleClick(tab, event) {
      this.searchData.pageNo = 1;
      this.getList();
    },
    getList() {
      this.loading = true;
      this.$request.post({
        url: "/ReportApi/business/sale/saleChecklist",
        params: this.searchData,
        success: (res) => {
          this.list = res.data;
          this.total = res.total;
        },
        finally: () => {
          this.loading = false;
        },
      });
    },
    handleCurrentChange(val) {
      this.searchData.pageNo = val;
      this.getList();
    },
    add() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if (this.loading) return;
          this.loading = true;
          const loading = this.$loading();
          var expressNos = "";
          var strList = this.ruleForm.order_nos.split("\n");
          for (var i = 0; i < strList.length; i++) {
            if (strList[i]) {
              expressNos += strList[i];
              if (i != strList.length - 1) {
                expressNos += ",";
              }
            }
          }
          this.$request.post({
            url: "/ReportApi/business/sale/saveSale",
            params: {

              tenantId: localStorage.getItem("tenantInfoid"),
              busId: localStorage.getItem("ids"),
              expressNos: expressNos,
              issue: this.ruleForm.remark,
              img: this.imgUrl,
            },
            success: (res) => {
              this.$message.success("提交成功");
              this.ruleForm.order_nos = "";
              this.ruleForm.remark = "";
              this.imgUrl = "";
              this.getList();
            },
            finally: () => {
              this.loading = false;
              loading.close();
            },
          });
        } else {

          return false;
        }
      });
    },
    toFile() {
      this.$refs.imgHook.click();
    },
    fileChnage(e) {
      let file = e.target.files || e.dataTransfer.files;
      if (!file.length) return;
      if (file[0].size > 15 * 1024 * 1024) {
        this.$message.warning("上传图片不得大于15M");
        return;
      }

      const formData = new FormData();
      formData.append("file", file[0]);
      this.$request.post({
        url: "/ReportApi/business/basic/uploadImg",
        params: formData,
        success: (result) => {
          this.$message.success("上传文件成功");
          this.imgUrl = result.url;
        },
        error: (result) => {

        },
        finally: () => {


        },
      });













    },
  },
};
</script>

<style lang="scss">
#check_pieces {
  .el-form-item__content {
    line-height: 32px;
  }
  .el-textarea__inner:focus {
    border-color: #f56c6c;
  }
}
</style>
<style lang="scss" scoped>
#check_pieces {
  .admin_main_block {
    background: #fff;
    width: 100%;
    border: 1px solid #e7eaec !important;
    min-height: 855px;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 20px;
    .user_temp_title {
      margin: auto;
      padding-left: 20px;
      border-bottom: 1px solid #eeeeee;
      padding-bottom: 20px;
      height: 25px;
      margin-bottom: 20px;
      font-weight: bold;
    }
    .admin_form_main {
      .el-form-item {
        width: 400px;
      }
    }
  }
  .img_upload {
    display: inline-block;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
  }
  .img_wrap {
    height: 178px;
    width: 178px;
    border-radius: 5px;
    overflow: hidden;
    img {
      width: 100%;
      cursor: pointer;
    }
  }
  .home_fy_block {
    margin-top: 30px;
    text-align: right;
    margin-right: 10px;
  }
}
</style>
